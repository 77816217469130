



























































































import Axios from "axios";
import { BASE_API_URL } from "../config";
import { Component, Vue, Prop } from "vue-property-decorator";
import { UNIT_TYPE } from "../constants/constants";

@Component({
  components: {}
})
export default class AddressComponent extends Vue {
    /* eslint-disable */
  @Prop()
  public address: any;
  @Prop()
  public propertyType: any;
  @Prop()
  public hasUnit: any;

  public unit_types = UNIT_TYPE;

  public county = [];

  public stateAndCounty = [];

  public async getStatesAndCounty() {
    try {
      const response = await Axios.get(
        BASE_API_URL + "common/getStatesAndCounty",
      );
      this.stateAndCounty = response.data;
    } catch (error) {
      console.log(error);
    }
  }

  getCounty() {
    try {
       const county :any =  this.stateAndCounty.find((a:any) => a.code === this.address.state);
       return county && county.counties ? county.counties : null;
    } catch (error) {
      console.log(error);
    }
  }

  mounted() {
    this.getStatesAndCounty();
  }
}
