import NameModel from '../common/name.model'
import Address from '../common/address.model'

export class Processor {
    constructor() {
        this.address = new Address();
        this.fullName = new NameModel();
        this.createdOn = new Date();
        this.email = null;
        this.phone = null;
        this.fax = null;
        this.assignee = null;
        this.roleId = [];
        this.workflowTaskId = Number;
        this.mileStoneId= Number;
      }
    
      public address: Address;
    
      public fullName: NameModel;
    
      public SSN: any;

      public createdOn: Date;

      public email: any;

      public phone: any;
  
      public fax: any;

      public assignee: any;

      public roleId: any;

      public workflowTaskId: any;

      public mileStoneId: any;
    
}