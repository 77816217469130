var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('modal',{staticClass:"modal",attrs:{"size":"sm","centered":"","name":"updateEmail","hide-footer":true}},[_c('div',{staticClass:"modal--md-regular"},[_c('div',{staticClass:"tmpco-modal__content"},[_c('div',{staticClass:"tmpco-modal__header"},[_c('h4',{staticClass:"tmpco-modal__title"},[_vm._v("Update Email")]),_c('button',{staticClass:"tmpco-modal__close",attrs:{"type":"button","data-dismiss":"modal"},on:{"click":function($event){_vm.$modal.hide('updateEmail');
              _vm.emailExist = false;}}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.updateEmail)}}},[_c('div',{staticClass:"tmpco-modal__body"},[_c('div',{staticClass:"form-row col-12"},[_c('div',{staticClass:"form-group col-lg-12"},[_c('ValidationProvider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v(" Email *")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentData.email),expression:"currentData.email"}],staticClass:"login-input",attrs:{"name":"Email"},domProps:{"value":(_vm.currentData.email)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.currentData, "email", $event.target.value)},function($event){return _vm.removeValidation()}]}}),(errors)?_c('span',{staticClass:"login-error show"},[_vm._v(_vm._s(errors[0]))]):_vm._e(),(_vm.emailExist)?_c('span',{staticClass:"login-error show"},[_vm._v("Email Already Exist")]):_vm._e()]}}],null,true)})],1)])]),_c('div',{staticClass:"tmpco-modal__footer"},[_c('button',{staticClass:"tmpco-btn tmpco-btn__sm-default mr-2",attrs:{"type":"button"},on:{"click":function($event){_vm.noChange = true;_vm.updateEmail()}}},[_vm._v(" Cancel ")]),_c('button',{staticClass:"tmpco-btn tmpco-btn__sm-theme",attrs:{"type":"submit"}},[_vm._v(" Update ")])])])]}}])})],1)])]),_c('modal',{staticClass:"modal",attrs:{"size":"sm","centered":"","name":"updatePhone","hide-footer":true}},[_c('div',{staticClass:"modal--md-regular"},[_c('div',{staticClass:"tmpco-modal__content"},[_c('div',{staticClass:"tmpco-modal__header"},[_c('h4',{staticClass:"tmpco-modal__title"},[_vm._v("Update Phone")]),_c('button',{staticClass:"tmpco-modal__close",attrs:{"type":"button","aria-label":"Close"},on:{"click":function($event){_vm.$modal.hide('updatePhone');
              _vm.phoneExist = false;}}},[_vm._v(" × ")])]),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.updatePhone)}}},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"col-lg-8 col-xl-8"},[_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"rules":"required|length:14"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v(" Phone * ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentData.phone),expression:"currentData.phone"},{name:"mask",rawName:"v-mask",value:('(###) ###-####'),expression:"'(###) ###-####'"}],staticClass:"login-input",attrs:{"name":"Phone"},domProps:{"value":(_vm.currentData.phone)},on:{"change":function($event){return _vm.removeValidation()},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.currentData, "phone", $event.target.value)}}}),(_vm.phoneExist)?_c('span',{staticClass:"login-error show"},[_vm._v("Phone Already Exist")]):_vm._e(),(errors)?_c('span',{staticClass:"login-error show"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)])])]),_c('div',{staticClass:"tmpco-modal__footer "},[_c('button',{staticClass:"tmpco-btn tmpco-btn__sm-default mr-2",attrs:{"type":"button"},on:{"click":function($event){_vm.noChange = true;_vm.updatePhone();}}},[_vm._v(" Cancel ")]),_c('button',{staticClass:"tmpco-btn tmpco-btn__sm-theme",attrs:{"type":"submit"}},[_vm._v(" Update ")])])])]}}])})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }